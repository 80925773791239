@font-face {
    font-family: 'Font-Regular';
    src: url("./fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
    font-family: 'Font-Bold';
    src: url("./fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: 'Font-Bold1';
    src: url("./fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
    font-family: 'Font-Light';
    src: url("./fonts/Poppins/Poppins-Light.ttf");
}

@font-face {
    font-family: 'Font-Italic';
    src: url("./fonts/Poppins/Poppins-Italic.ttf");
}

:root {
    --sidebar-width: 300px;
    --sidebar-closed-width: 55px;
    --menu-height: 45px;
    --user-info-height: 130px;
}

:root {
    --honey: #FBC11E;
    --honey-theme2: #FFEED1;
    --honey-theme3: #FBF7F0;
    --honey-theme4: #FBF7F0;
    --honey-theme5: #e1faff;
    --honey-rgb: 251, 193, 30;
    --lavender: #DD8DE4;
    --lavender-theme2: #FAF2FB;
    --lavender-theme3: #FEF8FF;
    --lavender-theme4: #FEF9FF;
    --lavender-theme5: #e1faff;
    --lavender-rgb: 221, 141, 228;
    --turquoise: #0EB1D2;
    --turquoise-theme2: #E1FAFF;
    --turquoise-theme3: #F3FAFF;
    --turquoise-theme4: #C5F5FF;
    --turquoise-theme5: #e1faff;
    --turquoise-rgb: 14, 177, 210;
    --amber: #EC6D52;
    --amber-theme2: #FFEFEE;
    --amber-theme3: #FFF9F8;
    --amber-theme4: #FFF9F8;
    --amber-theme5: #e1faff;
    --amber-rgb: 236, 109, 82;
    --electric: #6C63FF;
    --electric-theme2: #E4E3FA;
    --electric-theme3: #F4FBFF;
    --electric-theme4: #E4E3FA;
    --electric-theme5: #e1faff;
    --electric-rgb: 108, 99, 255;
    --lime: #BBE13F;
    --lime-theme2: #F8FFE1;
    --lime-theme3: #FDFFF4;
    --lime-theme4: #F8FFE1;
    --lime-theme5: #e1faff;
    --lime-rgb: 187, 225, 63;
}

.bg-honey {
    background: var(--honey);
}

.bg-honey-them2 {
    background: var(--honey-theme2);
}

.bg-honey-them3 {
    background: var(--honey-theme3);
}

.bg-lavender {
    background: var(--lavender);
}

.bg-lavender-them2 {
    background: var(--lavender-theme2);
}

.bg-lavender-them3 {
    background: var(--lavender-theme3);
}

.bg-turquoise {
    background: var(--turquoise);
}

.bg-turquoise-them2 {
    background: var(--turquoise-theme2);
}

.bg-turquoise-them3 {
    background: var(--turquoise-theme3);
}

.bg-amber {
    background: var(--amber);
}

.bg-amber-them2 {
    background: var(--amber-theme2);
}

.bg-amber-them3 {
    background: var(--amber-theme3);
}

.bg-electric {
    background: var(--electric);
}

.bg-electric-them2 {
    background: var(--electric-theme2);
}

.bg-electric-them3 {
    background: var(--electric-theme3);
}

.bg-lime {
    background: var(--lime);
}

.bg-lime-them2 {
    background: var(--lime-theme2);
}

.bg-lime-them3 {
    background: var(--lime-theme3);
}

[data-theme=honey] {
    --theme1: var(--honey);
    --theme2: var(--honey-theme2);
    --theme3: var(--honey-theme3);
    --theme4: var(--honey-theme4);
    --theme5: var(--honey-theme5);
    --theme-rgb: var(--honey-rgb);
}

[data-theme=lavender] {
    --theme1: var(--lavender);
    --theme2: var(--lavender-theme2);
    --theme3: var(--lavender-theme3);
    --theme4: var(--lavender-theme4);
    --theme5: var(--lavender-theme5);
    --theme-rgb: var(--lavender-rgb);
}

[data-theme=turquoise] {
    --theme1: var(--turquoise);
    --theme2: var(--turquoise-theme2);
    --theme3: var(--turquoise-theme3);
    --theme4: var(--turquoise-theme4);
    --theme5: var(--turquoise-theme5);
    --theme-rgb: var(--turquoise-rgb);
}

[data-theme=amber] {
    --theme1: var(--amber);
    --theme2: var(--amber-theme2);
    --theme3: var(--amber-theme3);
    --theme4: var(--amber-theme4);
    --theme5: var(--amber-theme5);
    --theme-rgb: var(--amber-rgb);
}

[data-theme=electric] {
    --theme1: var(--electric);
    --theme2: var(--electric-theme2);
    --theme3: var(--electric-theme3);
    --theme4: var(--electric-theme4);
    --theme5: var(--electric-theme5);
    --theme-rgb: var(--electric-rgb);
}

[data-theme=lime] {
    --theme1: var(--lime);
    --theme2: var(--lime-theme2);
    --theme3: var(--lime-theme3);
    --theme4: var(--lime-theme4);
    --theme5: var(--lime-theme5);
    --theme-rgb: var(--lime-rgb);
}

[data-theme] {
    --bs-body-bg: var(--theme3) !important;
    --bs-primary-rgb: var(--theme-rgb);
    --white: #fff;
    --black: #000;
    --label-grey1-color: #3C4546;
    --label-grey2-color: #5f5e5e;
    --label-grey3-color: #787777;
    --label-grey4-color: #A7A7A7;
    --label-grey5-color: #BEBEBE;
    --label-grey6-color: #D9D9D9;
    --label-grey7-color: #EEF1FF;
    --alert-color: #420505;
    --alert-bg: #FCD9D9;
    --alert-message-icon-color: var(--theme1);
    --confirm-message-icon-color: var(--theme1);
    --alert-message-link-color: var(--theme1);
    --scrollbar-thumb-bg: #bbb;
    --sk-bg: var(--theme4);
    --logged-in-user-info-bg: var(--label-grey7-color);
    --tagsinput-bg: var(--theme4);
    --bs-body-font-size: 0.9rem;
    --search-bg: var(--theme2);
    --bg-primary: var(--theme1);
    --bg-primary-active: var(--theme1);
    --body-color: var(--label-grey3-color);
    --bs-body-color: var(--label-grey3-color);
    --heading-color: var(--label-grey3-color);
    --small-heading-color: var(--label-grey4-color);
    --label-color: var(--label-grey3-color);
    --placehoder-color: var(--label-grey4-color);
    --input-border-color: var(--label-grey5-color);
    --button-bg-primary: var(--bg-primary);
    --button-bg-primary-active: var(--bg-primary-active);
    --bs-link-color: var(--label-grey3-color);
    --bs-link-active-color: var(--black);
    --bs-link-hover-color: var(--label-grey2-color);
    --bs-navbar-color: var(--label-grey1-color);
    --form-heading: var(--label-grey3-color);
    --modal-header-color: var(--theme1);
    --alert-message-text-color: var(--theme1);
    --confirm-message-text-color: var(--theme1);
    --tagsinput-color: var(--theme1);
    --course-summary-card-border: var(--label-grey4-color);
    --course-summary-card-heading-color: var(--label-grey2-color);
    --course-summary-card-label-color: var(--label-grey2-color);
    --course-summary-card-value-color: var(--label-grey3-color);
    --course-summary-card-hr-border-color: var(--label-grey3-color);
    --bs-primary-link-color: var(--theme1);
    --card-bg: var(--white);
    --card-color: var(--body-color);
    --sidebar-menu-bg: var(--white);
    --bs-btn-outline-hover-color: var(--white);
    --modal-bg: var(--white);
    --table-header-color: var(--label-grey3-color);
    --table-body-color: var(--label-grey2-color);
}

body {
    background: var(--bs-body-bg) !important;
    margin: 0;
    font-family: 'Font-Regular', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}


/* h1,
h2,
h3,
h4 {
    font-family: "Font-Bold";
} */

.bold {
    font-family: "Font-Bold";
}

*:focus {
    outline: none !important;
    box-shadow: none !important;
}

.react-tagsinput * {
    font-family: 'Font-Regular', sans-serif !important;
}

code {
    font-family: 'Font-Regular', monospace;
}

.card {
    --bs-card-border-radius: 5px;
    --bs-card-bg: var(--card-bg);
    --bs-card-color: var(--card-color);
    box-shadow: 0px 2px 21px rgba(0, 0, 0, 0.08);
    border: none;
    background: var(--bs-card-bg);
}

.card-body {
    padding: 45px;
}

@media only screen and (max-width: 991px) {
    .card-body {
        padding: 20px;
    }
}

.heading {
    color: var(--heading-color);
    font-family: 'Font-Bold';
}

.small-heading {
    color: var(--small-heading-color);
    font-family: 'Font-Bold';
}

.login-logo {
    width: 86px;
    height: 86px;
}

label {
    color: var(--label-color);
}

.form-control,
.form-select {
    border: none;
    border-bottom: 1px solid var(--input-border-color);
    border-radius: 2px;
}

    .form-control:focus,
    .form-select:focus {
        outline: none;
        box-shadow: none;
        border-bottom: 1px solid var(--theme1);
    }

    .form-control::placeholder {
        color: var(--placehoder-color);
        opacity: 1;
    }

    .form-control:-ms-input-placeholder {
        color: var(--placehoder-color);
    }

    .form-control::-ms-input-placeholder {
        color: var(--placehoder-color);
    }

.btn {
    --bs-btn-border-radius: 5px;
}

.btn-primary {
    --bs-btn-bg: var(--button-bg-primary);
    --bs-btn-border-color: var(--button-bg-primary);
    --bs-btn-border-color: var(--button-bg-primary);
    --bs-btn-hover-bg: var(--button-bg-primary-active);
    --bs-btn-hover-border-color: var(--button-bg-primary-active);
    --bs-btn-active-bg: var(--button-bg-primary-active);
    --bs-btn-active-border-color: var(--button-bg-primary-active);
    --bs-btn-disabled-bg: var(--button-bg-primary-active);
    --bs-btn-disabled-border-color: var(--button-bg-primary-active);
}

.accordion-button {
    outline: none;
}

a,
.link {
    text-decoration: none;
    cursor: pointer;
    color: var(--bs-link-color) !important;
    outline: none;
}

    .link:hover,
    a:hover,
    .link.active,
    a.active {
        color: var(--bs-link-active-color) !important;
    }

.alert {
    --bs-alert-border-radius: 5px;
}

.alert-danger {
    --bs-alert-color: var(--alert-color);
    --bs-alert-bg: var(--alert-bg);
    --bs-alert-border-color: var(--alert-bg);
}

.sidebar-menu-submenu {
    --bs-accordion-body-padding-x: none;
    --bs-accordion-body-padding-y: none;
    --bs-accordion-border-width: 0px;
    --bs-accordion-btn-padding-x: none;
    --bs-accordion-btn-padding-y: none;
    --bs-accordion-active-color: none;
    --bs-accordion-active-bg: none;
}

    .sidebar-menu-submenu .list-group {
        --bs-list-group-border-width: 0px;
        --bs-list-group-item-padding-x: 10px;
        --bs-list-group-item-padding-y: 0px;
    }

.navbar {
    --bs-navbar-color: var(--bs-navbar-color);
}

.navbar-nav,
.nav {
    --bs-nav-link-padding-y: 0.5rem;
}

.sk {
    animation: skeleton 1s ease infinite !important;
}

@keyframes skeleton {
    to {
        opacity: 0.5;
    }
}

body * {
    transition: width 0.5s;
}

.form-heading {
    color: var(--form-heading);
}

.form-sub-heading {
    color: var(--form-heading);
}

.btn {
    border-radius: 3px;
    padding-left: 25px;
    padding-right: 25px;
}

.btn-outline-primary {
    --bs-btn-bg: #fff;
    --bs-btn-border-color: var(--button-bg-primary);
    --bs-btn-border-color: var(--button-bg-primary);
    --bs-btn-hover-bg: var(--button-bg-primary-active);
    --bs-btn-hover-border-color: var(--button-bg-primary-active);
    --bs-btn-active-bg: var(--button-bg-primary-active);
    --bs-btn-active-border-color: var(--button-bg-primary-active);
    --bs-btn-disabled-bg: var(--button-bg-primary-active);
    --bs-btn-disabled-border-color: var(--button-bg-primary-active);
    --bs-btn-color: var(--button-bg-primary);
    --bs-btn-border-color: var(--button-bg-primary);
    --bs-btn-hover-color: var(--bs-btn-outline-hover-color);
    --bs-btn-hover-bg: var(--button-bg-primary);
    --bs-btn-hover-border-color: var(--button-bg-primary);
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-bg: var(--button-bg-primary-active);
    --bs-btn-active-border-color: var(--button-bg-primary-active);
    --bs-btn-disabled-bg: transparent;
    border: 2px solid var(--button-bg-primary);
    --bs-btn-disabled-color: var(--button-bg-primary);
}

.modal {
    --bs-modal-header-border-width: 0px;
    --bs-modal-padding: 1rem 3rem 3rem 3rem;
    --bs-modal-header-padding: 1rem 3rem 1rem 3rem;
}

.invalid-msg {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
}

.react-tagsinput-tag {
    color: var(--tagsinput-color) !important;
    background: var(--tagsinput-bg) !important;
    letter-spacing: 0.03em !important;
    border: 1px solid var(--theme1) !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.dropdown-loading {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 10px;
}

.multiselect-container .chip {
    background: var(--bg-primary);
}

.multiselect-container .option:hover {
    background: var(--bg-primary);
}

.fw-light {
    font-family: 'Font-Light', sans-serif;
}

::-webkit-scrollbar-track {
    display: none !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-bg) !important;
    border-radius: 8px;
}

.table {    
    --bs-table-color: var(--table-body-color) !important;
    --bs-table-bg: var(--theme4);
    --bs-table-accent-bg: var(--theme4);
    --bs-table-striped-color: var(--table-body-color) !important;
    --bs-table-striped-bg: var(--theme5);
    --bs-table-active-color: var(--table-body-color);
    --bs-table-hover-color: var(--table-body-color);
}

/*thead,
tbody,
tfoot,
tr,
td,
th {
    border: none;
}

    tbody tr {*/
        /* box-shadow: 0px 5px 17px rgba(0, 0, 0, 0.05) !important; */
        /* box-shadow: 0px 4px 10px var(--label-grey7-color); */
        /*box-shadow: 0px 5px 17px 0px #0000000D;
        box-shadow: 0px 5px 17px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
    }*/

.table thead {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
}

.table thead {
    color: var(--table-header-color);
}

.table tr td {
    /* border-top: 1px solid var(--theme1);
    border-bottom: 1px solid var(--theme1); */
}

    .table tr td:first-child {
        /*border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;*/
        /* border-left: 1px solid var(--theme1); */
    }

    .table tr td:last-child {
        /*border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;*/
        /* border-right: 1px solid var(--theme1); */
    }

.pagination {
    --bs-pagination-active-bg: var(--theme1);
    --bs-pagination-active-border-color: var(--theme1);
}


/* 
.modal {
    --bs-modal-bg: var(--modal-bg);
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-box-shadow: var(--modal-box-shadow);
} */

.optionListContainer {
    z-index: 6 !important;
}

.react-tagsinput--focused {
    border-color: var(--theme1) !important;
}

.color-theme1 {
    color: var(--theme1);
}

.footer {
    background: var(--theme2);
    left: var(--sidebar-closed-width);
    color: var(--label-grey3-color);
    transition: left 0.5s;
    z-index: 0;
}

.sidebar-open .footer {
    left: var(--sidebar-width);
}

@media only screen and (max-width: 991px) {
    .footer {
        left: 0 !important;
        position: relative;
        width: 100%;
    }
}

.input-group-text {
    background: transparent;
    border: none;
}

.modal-header {
    color: var(--modal-header-color);
}

.card .form-heading {
    color: var(--theme1);
}

.nav-tabs,
.nav-tabs .nav-item,
.nav-tabs .nav-item button {
    border: none;
}

    .nav-tabs .nav-item .nav-link {
        border-bottom: 4px solid var(--label-grey6-color);
    }

    .nav-tabs .nav-link.active {
        border-bottom: 4px solid var(--theme1);
        color: var(--theme1);
    }

.form-check-input:checked {
    background-color: var(--theme1);
    border-color: var(--theme1);
}

.nav-tabs .nav-link.active {
    --bs-nav-tabs-link-active-bg: none;
}

.data-grid-content-row {
    background: var(--theme4);
    color: var(--table-body-color);
    box-shadow: 0px 5px 17px 0px #0000000D;
    box-shadow: 0px 5px 17px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}

.sidebar-content-body .accordion {
    --bs-accordion-bg: var(--white);
    --bs-accordion-btn-color: var(--theme1);
    --bs-accordion-btn-bg: var(--theme2);
    --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
    --bs-accordion-btn-focus-border-color: var(--theme1);
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: var(--white);
    --bs-accordion-active-bg: var(--theme1);
    --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27var%28--theme1%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
}

.sidebar-content-body .accordion-header {
    padding: 0px !important;
}

    .sidebar-content-body .accordion-header a {
        color: var(--theme1) !important;
    }

.sidebar-content-body .accordion-button {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    align-items: center;
    grid-gap: 10px;
}

    .sidebar-content-body .accordion-button:not(.collapsed) a {
        color: var(--white) !important;
    }

    .sidebar-content-body .accordion-button.collapsed a {
        color: var(--theme1) !important;
    }

.sidebar-content-body .accordion-item {
    color: var(--bs-body-color);
}

.PopupHeading {
    color: var(--theme1);
    font-size: 1.5rem;
}
