.fileupload {
    height: 150px;
    width: 100%;
    border: 2px dashed;
    border-color: var(--label-color);
    position: relative;
    overflow: hidden auto;
}

.fileupload-content {
    /* position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 25px;
    width: 80%; */
    color: var(--label-color);
    cursor: pointer;
}

.fileupload-files-tobeuploaded-filesize {
    font-size: 0.75rem;
}

.img-preview {
    max-height: 100%;
}