.sk-sidebar-menu .row {
    margin-top: 10px;
}

.sk-sidebar-menu-icon {
    border-radius: 50%;
    width: 19%;
    background: var(--sk-bg);
    display: inline-block;
    height: 40px;
    float: left;
}

.sk-sidebar-menu-link {
    width: 80%;
    background: var(--sk-bg);
    display: inline-block;
    height: 40px;
    float: right;
}

.closed .sk-sidebar-menu-link {
    display: none;
}

.closed .sk-sidebar-menu-icon {
    width: 100%;
}