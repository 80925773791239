.alert-message .modal-body {
    text-align: center;
}

.alert-message-icon .icon {
    font-size: 4rem;
    color: var(--alert-message-icon-color);
}

.alert-message-message {
    font-size: 1.25rem;
    color: var(--alert-message-text-color);
}

.danger.alert-message .alert-message-icon .icon {
    color: var(--confirm-message-icon-color);
}

.alert-message-link,
.alert-message-link *,
.alert-message-link:hover,
.alert-message-link:active {
    color: var(--alert-message-link-color);
    font-size: 1.25rem;
}

.alert-message-link .icon {
    font-size: 1.25rem !important;
}