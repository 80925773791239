@media only screen and (max-width: 991px) {
    .slider {
        min-height: auto;
    }
}

.sidebar-header.card {
    box-shadow: 10px 2px 21px rgb(0 0 0 / 8%);
    border-radius: 0px;
}

.mobile-sidebar-menu-open {
    background: #000;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    top: 0px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
}

.sidebar-header {
    position: fixed;
    left: var(--sidebar-closed-width);
    right: 0;
    height: var(--menu-height);
    transition: left 0.5s;
    z-index: 4;
}

.sidebar-header .navbar {
    height: var(--menu-height);
}

.sidebar-menu {
    position: fixed;
    left: 0;
    z-index: 3;
    top: 0;
    bottom: 0;
}

.sidebar-content {
    position: fixed;
    margin: auto;
    right: 0;
    top: var(--menu-height);
    bottom: 0;
    transition: left 0.5s;
    overflow: hidden auto;
}

.sidebar-content-body {
    position: relative;
}

.sidebar-menu {
    width: var(--sidebar-closed-width);
}

.sidebar-menu .heading {
    height: var(--menu-height);
}

.sidebar-content {
    left: var(--sidebar-closed-width);
}

.sidebar-open .sidebar-header {
    left: var(--sidebar-width);
}

.sidebar-open .sidebar-menu {
    width: var(--sidebar-width);
}

.sidebar-open .sidebar-content {
    left: var(--sidebar-width);
}

.accordion-button::after {
    display: none;
}

.accordion-item {
    outline: none;
}

.accordion-item * {
    outline: none;
}

.accordion-header,
.sidebar-menu .nav-link,
.sidebar-menu-popover .list-group-item .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 7px;
}

.sidebar-menu .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    border-radius: 7px;
}

.sidebar-menu-heading .heading {
    margin-top: 0px;
}

.accordion-header .nav-link {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0.21rem;
    margin-bottom: 0.21rem;
}

.accordion-header.active,
.accordion-header.active * {
    background: var(--theme1);
    outline: none;
}

.sidebar-open .accordion-button::after {
    display: inline-block;
}

.sidebar-menu-content {
    position: relative;
    overflow: hidden auto;
}

@media only screen and (max-width: 991px) {
    .sidebar-menu {
        width: 0px !important;
        bottom: 0;
        top: var(--menu-height);
    }
    .sidebar-header,
    .sidebar-content {
        position: relative !important;
        width: 100% !important;
        left: 0px !important;
        right: 0px !important;
    }
    .sidebar-content {
        top: 10px !important;
    }
    .container-fluid {
        padding: 0px;
    }
    .sidebar-open {
        overflow: hidden;
    }
    .sidebar-open .sidebar-menu {
        width: 85% !important;
        overflow: hidden auto;
    }
    .sidebar-menu .nav-link {
        width: 100%;
    }
}

.sidebar-header {
    border-radius: 0px;
}

.sidebar-menu {
    border-radius: 0px;
}

.sidebar-menu .accordion-header button {
    font-size: var(--bs-body-font-size);
}

.sidebar-menu .nav-link {
    /* font-size: 0.95rem; */
}

.sidebar-open .sidebar-menu .nav-link {}

.sidebar-menu .nav-link .icon {
    /* font-size: 1.8rem; */
    font-size: 1.3rem;
}

.sidebar-menu .accordion-header:hover:not(.active) * {
    color: var(--theme1);
}

.sidebar-open .sidebar-menu .nav-link .icon {
    /* font-size: 1.3rem; */
    margin-right: 0.8rem;
}

.sidebar-header .logo {
    height: 30px;
}

.sidebar-menu-content-menus {
    overflow: hidden auto;
    position: absolute;
    top: 0;
    margin: auto;
    bottom: var(--user-info-height);
    width: 100%;
}

.sidebar-menu-content-userinfo,
.sidebar-menu-heading {
    --bs-link-color: var(--white);
    --bs-link-active-color: var(--white);
    --sidebar-menu-bg: var(--theme1);
    background: var(--theme1);
}

.sidebar-menu-content-userinfo {
    position: absolute;
    margin: auto;
    bottom: 0;
    width: 100%;
}

.sidebar-menu {
    --bs-link-active-color: var(--theme1);
}

.sidebar-menu .nav-link.active {
    background: var(--theme1) !important;
    --bs-link-active-color: var(--white);
}

.sidebar-menu .list-group,
.sidebar-menu-popover .list-group {
    --bs-link-active-color: var(--theme1);
    background: var(--theme2) !important;
}

.sidebar-menu-content-userinfo .list-group {
    --bs-list-group-bg: var(--theme1);
    --bs-link-active-color: var(--white);
}

.sidebar-menu .list-group-item .nav-link.active:not(.sidebar-menu-content-userinfo .nav-link.active),
.sidebar-menu-popover .list-group-item .nav-link.active {
    background: var(--theme2) !important;
    --bs-link-active-color: var(--theme1) !important;
}

.nav-link:not(.active):not(.bg-link .nav-link) {
    --bs-link-active-color: var(--theme1);
}

.active.accordion-header .active.nav-link {
    --bs-link-active-color: var(--white);
}

.sidebar-menu .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23787777'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.sidebar-menu .active .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.sidebar-menu-content-userinfo .list-group-item,
.sidebar-menu-popover .list-group-item {
    border: none;
}

.sidebar-menu-content-userinfo .nav-link {
    margin: 0px;
}

.sidebar-menu .accordion-body {
    margin-left: 1.5rem;
}

.sidebar-menu-content-userinfo .nav-link:hover {
    background: var(--theme2);
    color: var(--theme1) !important;
}

.sidebar-menu-content-userinfo .nav-link {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

.sidebar-menu-content-userinfo {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.sidebar-menu-content-userinfo .nav-link.active {
    background: var(--white) !important;
    --bs-link-active-color: var(--theme1) !important;
}