/* .theme-radio {
    padding-left: 0px;
    position: relative;
} */

.theme-radio {
    padding-left: 3rem;
    position: relative;
}

.theme-radio.form-check .form-check-input {
    margin-left: -2.25em !important;
    margin-top: 1.2rem;
}


/* .theme-radio input {
    display: none;
} */


/* 
.theme-radio input:checked~label::after {
    position: absolute;
    height: 15px;
    width: 10px;
    border-bottom: 3px solid var(--black);
    border-right: 3px solid var(--black);
    content: "";
    transform: rotate(45deg);
    left: 0;
    top: -10px;
    right: 0;
    bottom: 0;
    margin: auto;
} */