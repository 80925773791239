.sk-page {
    width: 100%;
    animation: skeleton 1s ease infinite !important;
}

.sk-page-heading {
    height: 50px;
}

.sk-page-heading-text {
    background: #ddd;
    border-radius: 5px;
}

.sk-page-row {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border-radius: 7px;
    background: var(--theme4);
}

.sk-page .card-body {
    padding-bottom: 40px;
}